import React from "react";

import ContactBody from '../components/ContactBody';


//Style Sheet
import '../index.css';


class Contact extends React.Component{
render() {
    return (

    <p>
        <ContactBody />
    </p>
            
);
}

}
export default Contact;