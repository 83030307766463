import React from "react";

import ListenBody from '../components/ListenBody';


//Style Sheet
import '../index.css';


class Listen extends React.Component{
render() {
    return (

    <p>
        <ListenBody />

        <br /><br />
    </p>
    
);
}

}
export default Listen;