import React, { Component } from "react";

//Pages

import HomeBody from "../components/HomeBody";

//Style Sheet
import '../index.css';

class Home extends React.Component {
    render() {
    return (
        <div>
            
            
            <HomeBody />

            
        </div>
    );
    }
}
export default Home;