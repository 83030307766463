import React, { Component } from "react";

//Pages

import CrewBody from "../components/CrewBody";
//Style Sheet
import '../index.css';

class Crew extends React.Component {
    render() {
            return (
        <div>
            
            
            <CrewBody />

            
        </div>
    );
    } 

    }
export default Crew;